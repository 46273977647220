import { Link, IconButton } from 'gatsby-theme-material-ui';
import {
  Box, Typography, Tooltip,
} from '@mui/material';
import {
  IconCopy,
} from '@tabler/icons';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import MainLayout from '../components/MainLayout';
import SmallHero from '../components/SmallHero';
import Block from '../components/Block';
import backgroundImage from '../images/pawel-czerwinski--0xCCPIbl3M-unsplash.jpg';

export function Head() {
  return <title>Contact - Veikko Mäkinen</title>;
}

export default function Contact() {
  const phone = '+358 (0) 44 5910 413';
  const email = 'veikko@veikko.fi';

  return (
    <MainLayout>
      <SmallHero title="Contact - Ollaan yhteydessä!" backgroundImage={backgroundImage} overlayColor="#3B1209" overlayOpacity={0.6} />
      <Block>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '150%',
          }}
        >
          <Typography variant="h2" gutterBottom mb={5}>
            Veikko Mäkinen
          </Typography>

          <Box sx={{ mb: 2 }}>
            <Link underline="none" href="https://www.linkedin.com/in/vmakinen/">linkedin.com/in/vmakinen/</Link>
          </Box>

          <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-end' }}>
            <Box>
              <Link underline="none" href={`tel:${phone}`}>{phone}</Link>
            </Box>
            <Box>
              <Tooltip title="Kopioi leikepöydälle" placement="right" arrow>
                <IconButton size="small" onClick={() => { navigator.clipboard.writeText(phone); }}>
                  <IconCopy strokeWidth={1.5} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-end' }}>
            <Box>
              <Link underline="none" href={`mailto:${email}`}>{email}</Link>
            </Box>
            <Box>
              <Tooltip title="Kopioi leikepöydälle" placement="right" arrow>
                <IconButton onClick={() => { navigator.clipboard.writeText(email); }}>
                  <IconCopy strokeWidth={1.5} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <StaticImage
              src="../images/makinen-veikko-sq.jpg"
              alt="Veikko Mäkinen"
              placeholder="blurred"
              layout="constrained"
              width={250}
              imgStyle={{
                borderRadius: '50%',
                boxShadow: '2px 2px 5px #888',
              }}
              css={css`
                border-radius: 50%;
                box-shadow: 2px 2px 5px #888;
              `}
            />
          </Box>
          <Box sx={{ mb: 2, color: 'secondary' }}>
            . . .
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography align="center" gutterBottom>
              <strong>Mäkinen Management Oy</strong>
              <br />
              2213868-4
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography align="center" gutterBottom>
              <strong>Verkkolaskuosoite</strong>
              <br />
              003722138684
              <Tooltip title="Kopioi leikepöydälle" placement="right" arrow>
                <IconButton size="small" onClick={() => { navigator.clipboard.writeText('003722138684'); }}>
                  <IconCopy strokeWidth={0.5} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography align="center" gutterBottom>
              <strong>Välittäjä</strong>
              <br />
              003723327487
              <Tooltip title="Kopioi leikepöydälle" placement="right" arrow>
                <IconButton size="small" onClick={() => { navigator.clipboard.writeText('003723327487'); }}>
                  <IconCopy strokeWidth={0.5} />
                </IconButton>
              </Tooltip>
              <br />
              Apix Messaging Oy
            </Typography>
          </Box>
        </Box>
      </Block>

    </MainLayout>
  );
}

// export const Head = () => <title>Home Page</title>
